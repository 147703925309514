import BpkLoadingButton from '@skyscanner/backpack-web/bpk-component-loading-button';
import { cssModules } from '@skyscanner/backpack-web/bpk-react-utils';

import { withI18n } from '../../../services/i18n';
import GuestsRoomsChildrenSelect from '../../Common/GuestsRoomsChildrenSelect';
import DateRangeSelector from '../DateRangeSelector';
import DestinationSelector from '../DestinationSelector';

import Preference from './Preference';

import type { I18nShape } from '../../../services/i18n';
import type {
  AutosuggestSuggestionOrPopularDestinationShape,
  Maybe,
} from '../../../types';
import type { DestinationShape } from '@skyscanner-internal/falcon-shared-types/types/hotels-components/types';

import STYLES from './ExpandableLayout.module.scss';

const cls = cssModules(STYLES);

type Props = {
  i18n: I18nShape;
  destination: DestinationShape;
  checkInDate: Date;
  checkOutDate: Date;
  destinationLabel: string;
  childrenAges: string[];
  adults: number;
  rooms: number;
  suggestions: AutosuggestSuggestionOrPopularDestinationShape;
  onClearSuggestions: Function;
  onSuggestionSelected: Function;
  onSuggestionsFetchRequested: Function;
  onChangeOpenCheckinSelector: Function;
  onDatesChanged: Function;
  onGetPreference: Function;
  getGuestsRoomsChildren: Function;
  onSubmitClick: Function;
  submittingForm?: boolean;
  lightLabel?: boolean;
  validDestination: Maybe<boolean>;
  openCheckinSelector: Maybe<boolean>;
  freeCancellation?: boolean;
  showPreference: boolean;
  arrangeInline?: boolean;
};

const ExpandableLayout = ({
  adults,
  arrangeInline = false,
  checkInDate,
  checkOutDate,
  childrenAges,
  destination,
  destinationLabel,
  freeCancellation = false,
  getGuestsRoomsChildren,
  i18n: { translate },
  lightLabel = false,
  onChangeOpenCheckinSelector,
  onClearSuggestions,
  onDatesChanged,
  onGetPreference,
  onSubmitClick,
  onSuggestionSelected,
  onSuggestionsFetchRequested,
  openCheckinSelector,
  rooms,
  showPreference,
  submittingForm = false,
  suggestions,
  validDestination,
}: Props) => (
  <>
    <div
      className={cls(
        'ExpandableLayout__inputs',
        arrangeInline && 'ExpandableLayout__inputsInline',
      )}
    >
      <DestinationSelector
        className={cls(
          'ExpandableLayout__location',
          arrangeInline && 'ExpandableLayout__locationInline',
        )}
        inputClassName={cls('ExpandableLayout__input')}
        destinationLabel={destinationLabel}
        destination={destination}
        lightLabel={lightLabel}
        onSuggestionSelected={onSuggestionSelected}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onClearSuggestions}
        suggestions={suggestions}
        arrangeInline={arrangeInline}
        onChangeOpenCheckinSelector={onChangeOpenCheckinSelector}
        valid={validDestination}
      />

      <div
        className={cls(
          'ExpandableLayout__inputsDatesGuest',
          arrangeInline && 'ExpandableLayout__inputsDatesGuestInline',
        )}
        data-test-id="search-controls-datesguest"
      >
        <DateRangeSelector
          // @ts-ignore
          className={cls('ExpandableLayout__dateRange')}
          formFieldClassName={cls('ExpandableLayout__formField')}
          lightLabel={lightLabel}
          checkInDate={checkInDate}
          checkOutDate={checkOutDate}
          onDatesChanged={onDatesChanged}
          openCheckinSelector={openCheckinSelector}
          onChangeOpenCheckinSelector={onChangeOpenCheckinSelector}
          showLabel
        />
        <GuestsRoomsChildrenSelect
          className={cls('ExpandableLayout__guestsRoomsChildren')}
          label={translate('HotelStay_label_guests_rooms')}
          lightLabel={lightLabel}
          adults={adults}
          rooms={rooms}
          childrenAges={childrenAges}
          getGuestsRoomsChildren={getGuestsRoomsChildren}
        />
      </div>
    </div>
    <div
      className={cls(
        'ExpandableLayout__ctaWrapper',
        arrangeInline && 'ExpandableLayout__ctaWrapperInline',
        showPreference && 'ExpandableLayout__preferenceOnSearchBar',
      )}
    >
      {showPreference && (
        <Preference
          className={cls('ExpandableLayout__preferenceContainer')}
          onGetPreference={onGetPreference}
          freeCancellation={freeCancellation}
        />
      )}
      <BpkLoadingButton
        className={cls('ExpandableLayout__cta')}
        data-test-id="search-button"
        large
        loading={submittingForm}
        onClick={onSubmitClick}
        submit
        type="submit"
      >
        {freeCancellation && !showPreference
          ? translate('SearchControls_label_Submit_freeCancellation')
          : translate('SearchControls_label_Submit')}
      </BpkLoadingButton>
    </div>
  </>
);

export default withI18n(ExpandableLayout);
