import { Component } from 'react';
import type { ReactNode } from 'react';

import { addDays } from 'date-fns/addDays';

import { withBackendContext } from '../../../components-hotel-card-adjacent/backend-context';
import { DATE_FORMAT, withI18n } from '../../../services/i18n';
import { withConfiguration } from '../../../skyscanner-application/configuration';
import DataProvider from '../../DataProvider';

import { PriceDataContext } from './PriceDataContext';

import type { I18nShape } from '../../../services/i18n';
import type { ConfigurationShape } from '../../../skyscanner-application/types';
import type { BackendGatewayShape } from '../../../types';
import type { DestinationShape } from '@skyscanner-internal/falcon-shared-types/types/hotels-components/types';

type Props = {
  i18n: I18nShape;
  children: ReactNode;
  configs: ConfigurationShape;
  backendGateway: BackendGatewayShape;
  destination: DestinationShape | null;
};

class PriceDataProvider extends Component<Props> {
  fetchPriceData = async () => {
    const {
      configs: { enableCalendarWithPrice },
      i18n: { formatDate },
    } = this.props;

    if (enableCalendarWithPrice) {
      const stay = {
        adults: 2,
        rooms: 1,
        start: formatDate(new Date(), DATE_FORMAT.NON_LOCALISED_SHORT),
        end: formatDate(
          addDays(new Date(), 60),
          DATE_FORMAT.NON_LOCALISED_SHORT,
        ),
      };
      const { backendGateway, destination } = this.props;

      if (destination) {
        const { entityId } = destination;
        const priceArray = await backendGateway.flexibleDatesResult({
          entityIds: entityId,
          stay,
        });
        return priceArray?.length > 0 ? priceArray[0][String(entityId)] : [];
      }
    }
    return Promise.resolve([]);
  };

  render() {
    const { children, destination } = this.props;

    return (
      <DataProvider
        fetchData={this.fetchPriceData}
        updateOn={destination ? destination.entityId : null}
      >
        {(result: any) => (
          <PriceDataContext.Provider value={result}>
            {children}
          </PriceDataContext.Provider>
        )}
      </DataProvider>
    );
  }
}

export default withI18n(
  withConfiguration(withBackendContext(PriceDataProvider)),
);
