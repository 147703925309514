// TODO: [JIRA-XXXX] Do not import a specific component into a generic component
import SearchBar from '../../../screens/SearchBar';
import { withI18n, type I18nShape } from '../../../services/i18n';
import { getDayviewUrl } from '../../../services/url/url';
import BpkBreakpoint, { BREAKPOINTS } from '../BpkBreakpoint';

import type {
  Maybe,
  MappingShape,
  SearchControlsOnSubmitParams,
} from '../../../types';
import type { Culture } from '@skyscanner-internal/falcon-shared-types/types/Context';
import type { Stay } from '@skyscanner-internal/falcon-shared-types/types/HotelStay';
import type { DestinationShape } from '@skyscanner-internal/falcon-shared-types/types/hotels-components/types';

type Props = {
  destination: DestinationShape;
  stay: Stay;
  culture: Culture;
  openCheckinSelector?: boolean;
  filters?: Maybe<MappingShape>;
  i18n: I18nShape;
};

const redirectOnSubmit = ({
  checkIn,
  checkOut,
  childrenAges,
  destination,
  filters,
  freeCancellation,
  i18n,
  numberOfAdults,
  numberOfRooms,
}: SearchControlsOnSubmitParams) => {
  const newStay: Stay = {
    checkIn,
    checkOut,
    numberOfAdults,
    numberOfRooms,
    childrenAges,
  };

  window.location.assign(
    getDayviewUrl({
      destination,
      stay: newStay,
      freeCancellation,
      filters,
      i18n,
    }),
  );
};

const RedirectingSearchBarWithAboutBanner = ({
  culture,
  destination,
  filters = undefined,
  i18n,
  openCheckinSelector = false,
  stay,
}: Props) => (
  <BpkBreakpoint query={BREAKPOINTS.MOBILE}>
    {(isMobile: boolean) => (
      <>
        {/* @ts-ignore */}
        <SearchBar
          isMobile={isMobile}
          destination={destination}
          stay={stay}
          onSearchControlsSubmit={({
            filters: selectedFilters,
            ...rest
          }: SearchControlsOnSubmitParams) => {
            redirectOnSubmit({
              ...rest,
              i18n,
              // @ts-ignore
              filters: {
                ...(filters || {}),
                ...(selectedFilters || {}),
              },
            });
          }}
          openCheckinSelector={openCheckinSelector}
          expanded
        />
      </>
    )}
  </BpkBreakpoint>
);

export default withI18n(RedirectingSearchBarWithAboutBanner);
