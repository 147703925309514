import BpkModal from '@skyscanner/backpack-web/bpk-component-modal';

import { HASH_LOCATION_MODAL } from '../../../../services/routes';
import HashRoute from '../../../HashRoute';
import DestinationSelector from '../DestinationSelector';

import type {
  Maybe,
  AutosuggestSuggestionOrPopularDestinationShape,
} from '../../../../types';
import type { DestinationShape } from '@skyscanner-internal/falcon-shared-types/types/hotels-components/types';

type Props = {
  destinationLabel: string;
  destination: DestinationShape;
  lightLabel?: boolean;
  arrangeInline?: boolean;
  valid?: Maybe<boolean>;
  className?: Maybe<string>;
  onSuggestionSelected: Function;
  onSuggestionsFetchRequested: Function;
  onSuggestionsClearRequested: Function;
  suggestions: AutosuggestSuggestionOrPopularDestinationShape;
  onChangeOpenCheckinSelector?: Maybe<Function>;
  cancelLabel: string;
  onChangeInputValue?: Maybe<Function>;
};

const DestinationSelectorModal = ({
  arrangeInline = false,
  cancelLabel,
  className = undefined,
  destination,
  destinationLabel,
  lightLabel = false,
  onChangeInputValue = undefined,
  onChangeOpenCheckinSelector = undefined,
  onSuggestionSelected,
  onSuggestionsClearRequested,
  onSuggestionsFetchRequested,
  suggestions,
  valid = undefined,
}: Props) => (
  <HashRoute
    hash={HASH_LOCATION_MODAL}
    render={({ onClose }: any) => (
      <BpkModal
        id="hotel-destination-modal"
        isOpen
        onClose={onClose}
        title={destinationLabel}
        closeText={cancelLabel}
        getApplicationElement={() => document.getElementById('app-root')}
        closeOnEscPressed
      >
        <DestinationSelector
          className={className}
          destinationLabel={destinationLabel}
          destination={destination}
          lightLabel={lightLabel}
          onSuggestionSelected={(
            item: AutosuggestSuggestionOrPopularDestinationShape,
          ) => {
            onClose();
            onSuggestionSelected(item);
          }}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          suggestions={suggestions}
          arrangeInline={arrangeInline}
          isMobile
          onChangeOpenCheckinSelector={onChangeOpenCheckinSelector}
          onChangeInputValue={onChangeInputValue}
          valid={valid}
        />
      </BpkModal>
    )}
  />
);

export default DestinationSelectorModal;
