/* eslint-disable react/no-danger */

import { withRtlSupport } from '@skyscanner/backpack-web/bpk-component-icon';
import BpkAirportIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/airports';
import BpkCityIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/city';
import BpkCountryIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/flag';
import BpkAccommodationIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/hotels';
import BpkDefaultIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/location';

import type {
  AutosuggestSuggestionShape,
  PopularDestinationItemShape,
} from '../../types';
import type { DestinationShape } from '@skyscanner-internal/falcon-shared-types/types/hotels-components/types';

const AirportIcon = withRtlSupport(BpkAirportIcon);
const CityIcon = withRtlSupport(BpkCityIcon);
const CountryIcon = withRtlSupport(BpkCountryIcon);
const AccommodationIcon = withRtlSupport(BpkAccommodationIcon);
const DefaultIcon = withRtlSupport(BpkDefaultIcon);

export const replaceValue = (value?: string) => {
  if (!value) return '';
  if (value && value.includes('<em>')) {
    const newValue = value.replace(/<em>/g, '<b>').replace(/<\/em>/g, '</b>');
    return <span dangerouslySetInnerHTML={{ __html: newValue }} />;
  }
  return value;
};

export const puredValue = (value?: string) => {
  if (!value) {
    return '';
  }

  if (value && value.includes('<em>')) {
    return value.replace(/<em>/g, '').replace(/<\/em>/g, '');
  }

  return value;
};

export const getSuggestionValue = ({ entity }: { entity?: string }) =>
  entity ? String(entity) : undefined;

export const pureSuggestValue = (
  suggestion: AutosuggestSuggestionShape | PopularDestinationItemShape,
) => {
  const value = getSuggestionValue(suggestion);
  return puredValue(value);
};

export const getSubheadingValue = ({
  adm1,
  adm2,
  city,
  entity,
  nation,
}: DestinationShape) => {
  let items = [];
  if (city) {
    items.push(city);
  }
  if (adm2 && city !== adm2) {
    items.push(adm2);
  }
  if (adm1) {
    items.push(adm1);
  }
  if (nation) {
    items.push(nation);
  }

  if (items[0] === entity) {
    items = items.slice(1);
  }

  const values = items.join(', ');
  return replaceValue(values);
};

export const getSuggestionReplacedValue = (
  suggestion: AutosuggestSuggestionShape,
) => {
  const value = getSuggestionValue(suggestion);
  return replaceValue(value);
};

export const getPuredItem = (item: any) => {
  const { suggestion, ...rest } = item;
  const { adm1, adm2, city, entity, nation } = suggestion;
  if (entity) {
    suggestion.entity = puredValue(entity);
  }
  if (city) {
    suggestion.city = puredValue(city);
  }
  if (adm2) {
    suggestion.adm2 = puredValue(adm2);
  }
  if (adm1) {
    suggestion.adm1 = puredValue(adm1);
  }
  if (nation) {
    suggestion.nation = puredValue(nation);
  }

  return {
    suggestion,
    ...rest,
  };
};

// See all TravelAPi entity types:
// https://confluence.skyscannertools.net/x/tyAmAQ
const AccommodationTypes = [
  'Apartment',
  'GuestHouse',
  'Hotel',
  'Hostel',
  'PrivateHome',
  'ResidenceHotel',
  'Resort',
];

export const getSuggestionIcon = ({
  type,
}: AutosuggestSuggestionShape | PopularDestinationItemShape) => {
  if (type === 'City') {
    return CityIcon;
  }

  if (type && AccommodationTypes.includes(type)) {
    return AccommodationIcon;
  }

  if (type === 'Airport') {
    return AirportIcon;
  }

  if (type === 'Nation') {
    return CountryIcon;
  }

  return DefaultIcon;
};
