import throttle from 'lodash/throttle';
import { stringify } from 'query-string';

import { logExperimentChokepoint } from '../../../../logMiniEvent';

import autosuggestItems from './autosuggest-item';

import type { AutosuggestGatewayProps, AutosuggestListener } from './types';
import type { Culture } from '@skyscanner-internal/falcon-shared-types/types/Context';

type Value = {
  value: string;
};

const AutosuggestGateway = ({
  autosuggestExpVal = '',
  culture,
  pageType,
  platform,
  utid,
}: {
  culture: Culture;
  platform: string;
  pageType: string;
  utid: string;
  autosuggestExpVal?: string;
}): AutosuggestGatewayProps => {
  let calls: string[] = [];
  let listener: AutosuggestListener | null;

  const removeBeforeIndex = (array: any[], position: number) =>
    array.filter((_, idx) => idx > position);

  const fetchResults = ({ value }: Value) => {
    if (autosuggestExpVal) {
      logExperimentChokepoint('AutosuggestExp_Hotel');
    }
    const qs = stringify({
      rf: 'map',
      vrows: 10,
      autosuggestExp: autosuggestExpVal === '' ? undefined : autosuggestExpVal,
    });
    const { locale, market } = culture;
    const encodedQuery = value ? encodeURIComponent(value) : '';
    const baseUrl =
      'https://www.skyscanner.net/g/autosuggest-search/api/v1/search-hotel';

    return fetch(`${baseUrl}/${market}/${locale}/${encodedQuery}?${qs}`, {
      headers: {
        Accept: 'application/json',
        'Skyscanner-Client-Name': 'falcon',
        'Skyscanner-utid': utid,
        pageType,
        platform,
      },
    });
  };

  return {
    input: throttle(async ({ metrics, value }) => {
      if (!listener) {
        return;
      }

      calls.push(value);
      let status;
      let duration;

      try {
        const requestTime = Date.now();
        const response = await fetchResults({ value });
        duration = Date.now() - requestTime;
        status = response.status;

        const json = await response.json();
        const items = autosuggestItems(json, value);

        const callPosition = calls.indexOf(value);
        if (callPosition !== -1) {
          calls = removeBeforeIndex(calls, callPosition);
          listener.next(items);
        }
      } catch (err) {
        status = 500;
        listener.error(err);
      } finally {
        metrics.autoSuggestSearch({
          status,
          duration,
        });
      }
    }, 300),

    subscribe: (l) => {
      listener = l;
    },

    unsubscribe: () => {
      listener = null;
    },
  };
};

export default AutosuggestGateway;
